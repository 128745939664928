<template>
  <div>
    <!-- toolbar -->
    <v-toolbar dense flat>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="$router.push('/datasetGroups')"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Up to dataset list page</span>
      </v-tooltip>

      <v-toolbar-title>{{ $t("CreateProject") }}</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>

    <!-- content -->
    <v-container>
      <v-row>
        <!-- dataset name -->
        <v-col cols="12" md="6" class="pb-0" id="datasetnameSection">
          <v-text-field
            v-model="dataset_name"
            :label="$t('projectName')"
            outlined
            dense
            :error-messages="nameErrors"
            @input="$v.dataset_name.$touch()"
            @blur="$v.dataset_name.$touch()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="py-0">
          <v-textarea
            label="Descripstion"
            outlined
            v-model="description"
            rows="1"
            row-height="20"
            :error-messages="descriptionErrors"
            @input="$v.description.$touch()"
            @blur="$v.description.$touch()"
          ></v-textarea>
        </v-col>

        <!-- objective -->
        <v-col cols="12" class="pt-0">
          <div class="text-h6">Select an objective</div>
          <div>
            Then select an objective, which is the outcome that you want to
            achieve with the trained model.
          </div>
        </v-col>

        <v-radio-group v-model="radioGroup" row class="mt-0">
          <v-col
            v-for="(card, index) in cards"
            :key="card.title"
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <!-- img -->
              <v-img :src="card.imgURL" height="200px"></v-img>

              <!-- action -->
              <v-radio
                :key="index"
                :value="index"
                class="ml-3"
                :disabled="card.disabled"
              >
                <template slot="label">
                  <div>
                    <!-- title -->
                    <v-card-title v-text="card.title"> </v-card-title>

                    <!-- subtitle -->
                    <v-card-subtitle v-text="card.subtitle"> </v-card-subtitle>
                  </div>
                </template>
              </v-radio>
            </v-card>
          </v-col>
        </v-radio-group>

        <!-- file -->
        <v-col cols="12" md="6" id="fileSection">
          <!-- <v-file-input
            :label="$t('fileInputCSV')"
            accept=".csv"
            outlined
            dense
            show-size
            v-model="uploadFile"
            :error-messages="uploadFileErrors"
            @input="$v.uploadFile.$touch()"
            @blur="$v.uploadFile.$touch()"
          ></v-file-input> -->
          <v-col cols="12" class="d-flex justify-start pl-0">
            <v-btn
              color="primary"
              class="me-3"
              @click.prevent="onUpload"
              :disabled="onUploadBtnLocked"
            >
              {{ $t("submit") }}
            </v-btn>
            <v-btn text @click="$router.push('/datasetGroups')"> close </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import data from "@/models/data";

export default {
  data() {
    return {
      dataset_name: null,
      cards: [
        {
          title: "Causal Discovery",
          subtitle:
            "Generate causality from the datasets to gain a explainable model",
          imgURL: require("@/assets/CausalGraphCoverFigure.png"),
          disabled: false,
          domainId: 3,
        },
        {
          title: "Kairos- Omnichannel solution",
          subtitle:
            "Optimize budget allocation and provides a variety of marketing tactic",
          imgURL: require("@/assets/OmnichannelMarketing.jpg"),
          disabled: false,
          domainId: 1,
        },
        // {
        //   title: "Order forecasting",
        //   subtitle:
        //     "Predict gaps between orders (time-to-next-order) and order sizes for old accounts",
        //   imgURL: require("@/assets/Predict.jpg"),
        //   disabled: true,
        //   domainId: 2,
        // },

        // {
        //   title: "Drug trial",
        //   subtitle:
        //     "Predict whether a Phase II drug trial would advance to Phase III",
        //   imgURL: "https://source.unsplash.com/ZHys6xN7sUE",
        //   disabled: true,
        //   domainId: 4,
        // },
      ],
      radioGroup: 0,
      // uploadFile: null,
      onUploadBtnLocked: false,
      description: null,
    };
  },
  validations: {
    dataset_name: {
      required,
      maxLength: maxLength(32),
    },
    description: {
      maxLength: maxLength(512),
    },
    // uploadFile: {
    //   required,
    // },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.dataset_name.$dirty) return errors;
      !this.$v.dataset_name.required && errors.push(this.$t("required"));
      console.log(this.$v.dataset_name.$params);
      !this.$v.dataset_name.maxLength &&
        errors.push(
          `Must be at most ${this.$v.dataset_name.$params.maxLength.max}`
        );
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      console.log(this.$v.description.$params);
      !this.$v.description.maxLength &&
        errors.push(
          `Must be at most ${this.$v.description.$params.maxLength.max} characters.`
        );
      return errors;
    },
    // uploadFileErrors() {
    //   const errors = [];
    //   if (!this.$v.uploadFile.$dirty) return errors;
    //   !this.$v.uploadFile.required && errors.push(this.$t("required"));
    //   return errors;
    // },
  },
  methods: {
    async onUpload() {
      this.onUploadBtnLocked = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.$invalid);
        if (this.$v.dataset_name.$invalid) {
          console.log("this.$v.dataset_name", this.$v.dataset_name.$invalid);
          this.$vuetify.goTo("#datasetnameSection");
        }
        // else if (this.$v.uploadFile.$invalid) {
        //   console.log("this.$v.uploadFile", this.$v.uploadFile.$invalid);
        //   this.$vuetify.goTo("#fileSection");
        // }
      } else {
        const payload = {
          // file: this.uploadFile,
          // dataset_name: this.dataset_name,
          // dataset_type: this.cards[this.radioGroup].title,
          name: this.dataset_name,
          domain: this.cards[this.radioGroup].domainId,
          description: this.description,
        };
        // let res = await data.uploadData(payload);
        // if (res === false) {
        //   this.$dialog.message.warning(this.$t("uploadFileError"), {
        //     position: "top",
        //   });
        // } else if (res[0] === "Success!") {
        //   this.$dialog.message.success(this.$t("uploadFileSuccess"), {
        //     position: "top",
        //   });
        //   this.$router.push("/datasetGroups");
        // } else {
        //   this.$dialog.message.error(this.$t("systemError"), {
        //     position: "top",
        //   });
        // }
        const res = await data.createDatasetGroup(payload);
        console.log("res", res);
        if (res.status !== 201) {
          this.$dialog.message.warning(this.$t("uploadFileError"), {
            position: "top",
          });
        } else if (res.status === 201) {
          this.$dialog.message.success(this.$t("uploadFileSuccess"), {
            position: "top",
          });
          this.$router.push("/datasetGroups");
        } else {
          this.$dialog.message.error(this.$t("systemError"), {
            position: "top",
          });
        }
      }
      this.onUploadBtnLocked = false;
    },
  },
};
</script>
